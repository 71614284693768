import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PromoCTA from '../PromoCTA/PromoCTA';
import { JumboButton } from '../JumboButton/JumboButton';
import digital_leader from '../../../static/images/about_page_icons/digital_leader.png';
import software_products from '../../../static/images/about_page_icons/software_products.png';
import seasoned_professionals from '../../../static/images/about_page_icons/seasoned_professionals.png';
import mountain1 from '../../../static/images/mountain1.png';

import aboutData from '../../../content/pages/about.json';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  hero: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(40),
    paddingBottom: theme.spacing(30),

    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(12),
    },
  },
  heroCover: {
    position: 'absolute',
    right: 0,
    width: '50vw',
    height: '100vh',
    zIndex: -1,
  },
  title: {
    marginTop: 'auto',
    lineHeight: 1,
  },
  subtitle: {
    fontSize: '2em',
    marginBottom: 'auto',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  section: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(12),
    },
  },
  lead: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
  },
  rightPadding: {
    paddingRight: theme.spacing(8),
  },
  innovationButton: {
    marginTop: theme.spacing(6),
  },
  leadContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(12),
    },
  },
  leadTitle: {
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: '4em',
  },
  bottomImage: {
    backgroundImage: `url(${mountain1})`,
    backgroundSize: 'cover',
  },
}));

const points = [
  {
    img: seasoned_professionals,
    title: 'A team of seasoned professionals',
    description:
      'The SpiceFactory team consist of seasoned professionals in product management, UX, design and scalable software architectures. We nourish software craftsmanship and deliver value, fast!',
  },
  {
    img: digital_leader,
    title: 'Our goal is to help you become a digital leader in your industry',
    description:
      'Our goal is to help our clients embrace the constant technological change and become digital leaders in their industries.',
  },
  {
    img: software_products,
    title: 'Building software products that solve real problems',
    description:
      'Our mission is to build software products that solve real problems, build brands, grow businesses, and deliver transformative experiences for the end users.',
  },
];

function About() {
  const classes = useStyles();

  return (
    <Fragment>
      <Container maxWidth={false} className={classes.hero}>
        <Typography className={classes.title} variant="h1">
          {aboutData.title}
        </Typography>
        <Typography className={classes.subtitle}>{aboutData.subtitle}</Typography>
      </Container>

      <Container maxWidth={false}>
        <Grid container spacing={8}>
          {points.map((point, i) => (
            <Grid key={i} item lg={4} md={6}>
              <img src={point.img} alt={point.title} loading="lazy" />
              <Typography component="h2" variant="h3" gutterBottom>
                {point.title}
              </Typography>
              <Typography>{point.description}</Typography>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Box className={classes.leadContainer} component={Container}>
        <Typography align="center" className={classes.leadTitle}>
          Crafting cutting-edge products that deliver results since 2014
        </Typography>
      </Box>

      <Container className={classes.section}>
        <Typography variant="h2" gutterBottom>
          What we do
        </Typography>
        <Typography variant="subtitle2">
          We provide strategy consulting, research, user experience design, and new product development services. We
          take your idea from concept to implementation and help you get your product to the target market. And when we
          build your product, we build it for scale to accommodate <strong>the growth of your business</strong>.{' '}
        </Typography>
      </Container>

      <Container className={classes.section}>
        <Typography variant="h2" gutterBottom>
          Fueling innovation across industries
        </Typography>
        <Typography variant="subtitle2">
          We work with forward-thinking enterprises and innovative startups that want to shake their markets, capture
          new growth, and set a new high bar for digital leadership. Our clients come from across industries but with a
          similar goal - they want to break their business model in order to improve it with technology.
        </Typography>
        <JumboButton variant="contained" color="primary" href="/process" className={classes.innovationButton}>
          View our process
        </JumboButton>
      </Container>

      {/*<StaticImage src="../../../static/images/mountain1.png" alt="A mountain with a flag" />*/}
      <PromoCTA text="Work with SpiceFactory" actionText="Get in touch" to="/contact-us" />
    </Fragment>
  );
}

export default About;
