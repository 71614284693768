import React from 'react';
import Layout from '../layout';
import About from '../components/About/About';
import JsxSeo from '../components/SEO/JsxSEO';

function AboutPage({ location }) {
  return (
    <Layout>
      <JsxSeo
        title="About"
        description="We craft products to be loved by millions and help businesses grow to new heights."
        location={location}
      />
      <About />
    </Layout>
  );
}

export default AboutPage;
