import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const JumboButton = withStyles(theme => ({
  root: {
    fontSize: theme.typography.button.fontSize,
    lineHeight: '3.125rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    textTransform: 'none',
    borderRadius: '0px',
  },
  iconSizeMedium: {
    '& > *:first-child': {
      fontSize: theme.typography.button.fontSize,
    },
  },
}))(Button);
